<template>
  <b-container fluid>
    <b-row class="mb-4 justify-content-between" style="gap:20px">
      <b-col cols="12" xl="7" md="6" class="mb-2">
        <SearchInput v-model="filters.name" />
      </b-col>

      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="d-flex justify-content-end flex-wrap" style="gap: 40px;">
        <ActionButton
          v-b-modal.sendToSelectedCustomer
          :isLoading="isSending"
          label="提供"
        />
        <ActionButton
          v-b-modal.sendToAllCustomers
          :isLoading="isSubmitting"
          label="提供全部"
        />
        </div>
      </b-col>
    </b-row>

    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:title-transform-checkbox>
        <b-form-checkbox
          @change="handleSelectAllChange"
          :disabled="isLoading"
          v-model="isSelectAll"
        ></b-form-checkbox>
      </template>

      <template v-slot:custom-checkbox="{ item }">
        <b-form-checkbox
          @change="onHandleChangeItem($event, item.id)"
          :checked="checkboxCheckedMap(item.id)"
        ></b-form-checkbox>
      </template>

      <template v-slot:custom-action="{ item }">
        <b-dropdown
          variant="link"
          toggle-class="text-white text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon
              icon="three-dots"
              font-scale="1.5"
              class="text-dark"
            ></b-icon>
          </template>

          <b-dropdown-item v-on:click="assignManualCustomer(item.id)">
            <span>+</span> 提供
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </TableData>

    <CouponModal
      v-model="isBirthDay"
      id="sendToManualCustomer"
      title="向選定的顧客提供此優惠券"
      :couponOptions="couponOptions"
      :isSending="isSending"
      @assign="assignCustomerToCoupon"
    />

    <CouponModal
      v-model="isBirthDay"
      id="sendToSelectedCustomer"
      title="向選定的顧客提供此優惠券"
      :couponOptions="couponOptions"
      :isSending="isSending"
      @assign="assignToSelectedCustomer"
    />

    <CouponModal
     v-model="isBirthDay"
      id="sendToAllCustomers"
      title="向所有顧客提供此優惠券"
      :couponOptions="couponOptions"
      :isSending="isSubmitting"
      @assign="assignToAllCustomer"
    />

    <Pagination
      v-if="!isLoading && paginatedData.length"
      v-model="currentPage"
      :list-data="filterCustomer"
      :limit="limit"
    />
  </b-container>
</template>

<script>
import customerService from '../../../services/customer.service'
import couponService from '../../../services/coupon.service'
import Pagination from '../../Pagination.vue'
import TableData from '../../TableData.vue'
import SearchInput from '../../SearchInput.vue'
import ActionButton from '../ActionButton.vue'
import CouponModal from '../CouponModal.vue'

export default {
  name: 'LoyaltyPrograms',
  data: () => ({
    customerList: [],
    currentPage: 1,
    limit: 10,
    isBirthDay: false,
    selectedOption: null,

    filters: {
      name: '',
      start: '',
      end: ''
    },

    breadcrumbItems: [
      { label: '優惠券管理', to: '/loyalty-programs' },
      { label: '向客戶提供' }
    ],

    couponOptions: [
      {
        type: 'normal',
        title: '普通優惠券',
        description: '適用於所有用戶的標準促銷和折扣。',
        isBirthDay: false
      },
      {
        type: 'birthday',
        title: '生日優惠券',
        description: '為用戶生日提供的特別折扣。',
        isBirthDay: true
      }
    ],

    headers: [
      { text: '', value: 'checkbox' },
      { text: '會員編號', value: 'posMemberId' },
      { text: '使用者身分', value: 'id' },
      { text: '姓名', value: 'name' },
      { text: '電話號碼', value: 'phoneNumber' },
      { text: '日期登記', value: 'joinDate' },
      { text: '出生日期', value: 'birthdate' },
      { text: '操作', value: 'action' }
    ],

    isLoading: false,
    isSubmitting: false,
    isSending: false,
    isSelectAll: false,
    customerIds: [],
    customerId: null
  }),

  components: {
    Pagination,
    TableData,
    SearchInput,
    ActionButton,
    CouponModal
  },

  mounted () {
    this.getAllCustomers()
  },

  computed: {
    filterCustomer () {
      return this.customerList
        .filter((item) =>
          this.filters.name.length ? item?.name?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
          item?.posMemberId?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
            item?.id?.toString().toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.filters.name.toLowerCase().replace(/\s+/g, '')) ||
            item?.phoneNumber?.toString().toLowerCase()
              .replace(/\s+/g, '')
              .includes(this.filters.name.toLowerCase().replace(/\s+/g, ''))
            : true
        ).sort(
          (a, b) =>
            +new Date(b.joinDate) - (+new Date(a.joinDate))
        )
    },

    paginatedData () {
      return this.filterCustomer.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    }
  },
  watch: {
    filterCustomer () {
      this.currentPage = 1
    }
  },
  methods: {
    async getAllCustomers () {
      this.isLoading = true
      try {
        const res = await customerService.getAllCustomers()
        this.customerList = res.data
      } catch (err) {
        this.makeToast('danger', '發生錯誤，請稍後再試。', err)
      }
      this.isLoading = false
    },

    async assignToSelectedCustomer () {
      if (!this.customerIds.length) {
        this.makeToast('danger', '尚未選擇客戶名單', '發送優惠券前請選擇客戶名單。')
        return
      }
      this.isSending = true
      this.$bvModal.hide('sendToSelectedCustomer')
      try {
        const res = await couponService.assignToSelectedCustomers(this.$route.params.id, this.customerIds, this.isBirthDay)
        this.handleResponse(res, '優惠券已提供給選定的客戶')
      } catch (err) {
        this.makeToast('danger', '發生錯誤，請稍後再試。', err)
      }
      this.isSending = false
    },

    async assignToAllCustomer () {
      this.$bvModal.hide('sendToAllCustomers')
      this.isSubmitting = true
      try {
        const res = await couponService.assignCouponToAll(this.$route.params.id, this.isBirthDay)
        this.handleResponse(res, '優惠券已發放給所有顧客')
      } catch (err) {
        this.makeToast('danger', '發生錯誤，請稍後再試。', err)
      }
      this.isSubmitting = false
    },

    assignCustomerToCoupon () {
      this.$bvModal.hide('sendToManualCustomer')
      couponService.assignCustomerToCoupon(this.$route.params.id, this.customerId, this.isBirthDay).then(
        res => {
          this.handleResponse(res, '優惠券已發給顧客')
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },

    assignManualCustomer (customerId) {
      this.$bvModal.show('sendToManualCustomer')
      this.customerId = customerId
    },

    handleResponse (res, successMessage) {
      if (res.success) {
        this.makeToast('success', '向客戶提供', successMessage)
      } else {
        this.makeToast('danger', '向客戶提供', res.message)
      }
    },

    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },

    handleSelectAllChange (value) {
      this.customerIds = value ? this.customerList.map(item => item.id) : []
    },

    onHandleChangeItem (checked, id) {
      if (checked) {
        this.customerIds.push(id)
      } else {
        this.customerIds = this.customerIds.filter(element => element !== id)
      }
      if (this.isSelectAll) {
        this.isSelectAll = this.customerIds.length === this.customerList.length
      }
    },

    checkboxCheckedMap (id) {
      return this.customerIds.includes(id)
    }
  }
}
</script>
