<template>
    <div>
      <b-button
        :disabled="isLoading"
        :variant="variant"
        style="float: right"
        class="btn btn-secondary btn--h46px justify-content-center px-5"
      >
        <b-spinner v-if="isLoading" small class="mr-2"></b-spinner>
        <span class="mr-2" v-else>+</span> {{ label }}
      </b-button>
    </div>
  </template>

<script>
export default {
  props: {
    isLoading: Boolean,
    label: String,
    variant: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
