<template>
  <b-modal :id="id" :title="title" size="lg" hide-footer>
    <h6 class="my-3">請選擇優惠券類型:</h6>
    <b-card-group deck>
      <b-card
        v-for="(option, index) in couponOptions"
        :key="index"
        :class="{ 'bg-primary text-white': localValue === option.isBirthDay }"
        @click="updateValue(option.isBirthDay)"
        class="mb-2"
      >
        <b-card-body>
          <b-card-title>{{ option.title }}</b-card-title>
          <b-card-text>{{ option.description }}</b-card-text>
        </b-card-body>
        <b-icon
          v-if="localValue === option.isBirthDay"
          icon="check-circle-fill"
          class="position-absolute"
          style="top: 10px; right: 10px"
        ></b-icon>
      </b-card>
    </b-card-group>
    <div class="d-flex my-4 align-items-center justify-content-center">
      <b-button
        @click="$emit('assign')"
        :disabled="isSending"
        type="button"
        variant="primary"
        class="rounded-10px px-3 px-lg-5"
      >提供</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    couponOptions: Array,
    isSending: Boolean,
    value: Boolean
  },
  computed: {
    localValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    updateValue (value) {
      this.localValue = value
    }
  }
}
</script>
